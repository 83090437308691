<template>
  <form>
    <div v-if='itemHasErrors' class='item-errors alert alert-danger'>
      <translate>Pool config has errors</translate>
    </div>
    <FormulateInput name='enabled' type='hidden' v-model='item.enabled' />
    <FormulateInput
        :label="$gettext('POOL URL')"
        :tooltip="$gettext('Choose the proper pool protocol. StratumV2 recommended!')"
        @validation="updatePoolConfigValidation({url: $event})"
        :errors='itemErrors.url'
        name='url'
        v-model='item.url'
        type='url'
        :validation="[['matches', /^(drain|stratum\+tcp|stratum\+ssl|stratum\+insecure|stratum2\+tcp|http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/]]"
        :validation-messages="{ matches: 'Pool urls must match a valid pool url definition' }"
        error-behavior="blur"
        placeholder='eg. stratum+tcp://stratum.antpool.com:3333'
    />
    <FormulateInput
        :label="$gettext('POOL USER')"
        :tooltip="$gettext('Set a pool user.')"
        :validation="[['matches', /^[a-zA-Z0-9_\-]+$/]]"
        :validation-messages="{ matches: 'Username must only contain alpha numerics, underscores and dashes.' }"
        @validation="updatePoolConfigValidation({username: $event})"
        :errors='itemErrors.username'
        error-behavior="blur"
        name='username'
        v-model='item.username'
        type='text'
        placeholder='eg. myusername'
    />
    <!--FormulateInput
        :label="$gettext('POOL APi-KEY')"
        :tooltip="$gettext('Pool api-key.')"
        name='api_key'
        type='text'
        v-model='item.api_key'
        error-behavior="blur"
        placeholder='optional'
    /-->
    <FormulateInput
        :label="$gettext('POOL PASSWORD')"
        :tooltip="$gettext('Pool password.')"
        name='password'
        type='text'
        v-model='item.password'
        error-behavior="blur"
        placeholder='optional'
    />
  </form>
</template>

<script>
import { FieldValidator as Validations } from '@/plugins/vuex-orm/validations'

export default {
  extends: Validations,
  name: 'farm-create-pool-config',
  props: ['updatePoolConfigValidation'],
  data () {
    return {
      itemFields: { ...this.item }
    }
  }
}
</script>
