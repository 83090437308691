import array from 'lodash'

// MODEL VALIDATIONS CODE

export const validationCacheKey = (model, values) => {
  const key = { model: model, id: values.id, errors: values.errors }
  return JSON.stringify(key)
}

const ModelValidations = store => {
  return {
    install (components, options) {
      const { Model } = components
      const errorFieldModel = {
        errors: Model.attr({})
      }

      const _saveGetFieldsMethod = Model.prototype.$fields
      Model.prototype.$fields = function () {
        const existing = _saveGetFieldsMethod.call(this)
        return Object.assign({}, existing, errorFieldModel)
      }

      Model.clearErrors = function (id, associations = []) {
        const promiseArray = []

        promiseArray.push(
          this.update({
            where: id,
            data: { errors: {} }
          })
        )

        associations.forEach((assoc) => {
          if (Array.isArray(assoc)) {
            const assocType = assoc.shift()
            const models = this.query().with(assocType).find(id)[assocType]

            models.forEach((model) => {
              const modelType = Object.getPrototypeOf(model).constructor

              promiseArray.push(modelType.clearErrors(model.id, assoc))
            })
          } else {
            const model = this.query().with(assoc).find(id)[assoc]
            const modelType = Object.getPrototypeOf(model).constructor

            promiseArray.push(modelType.clearErrors(model.id))
          }
        })

        return Promise.all(promiseArray)
      }

      Model.clearErrorForField = function (id, field) {
        const errors = this.find(id).errors

        const newErrors = { ...errors }
        delete newErrors[field]

        this.update({
          where: id,
          data: { errors: newErrors }
        })
      }

      Model.validate = function (id, data, options = {}) {
        const errors = this.find(id).errors

        const promiseArray = []

        if (typeof data.detail === 'string') {
          const rootKey = options.rootKey || '__root__'
          promiseArray.push(
            this.update({
              where: id,
              data: { errors: { [rootKey]: data.detail, __root__: data.detail } }
            })
          )
        } else {
          promiseArray.push(Promise.all(
            data.detail.map(async (val) => {
              const loc = val.loc
              const depth = options.depth
              let attrPath

              if (depth) {
                attrPath = array.drop(loc, depth)
              } else {
                attrPath = loc
              }

              // path resolves to a field
              if (attrPath.length === 1) {
                const field = attrPath[0]
                errors[field] = val.msg

                await this.update({
                  where: id,
                  data: { errors }
                })

                // path resolves to a related model
              } else {
                const attr = attrPath.shift()
                const attrBranch = attrPath.shift()
                let model

                // resolves a hasMany relation
                if (typeof (attrBranch) === 'number') {
                  model = this.query().with(attr).find(id)[attr][attrBranch]
                  const modelType = Object.getPrototypeOf(model).constructor

                  promiseArray.push(modelType.validate(
                    model.id, {
                      detail: [{ loc: attrPath, msg: val.msg }]
                    }
                  ))
                } else {
                  // resolves a hasOne relation
                  model = this.query().with(attr).find(id)[attr]
                  const modelType = Object.getPrototypeOf(model).constructor

                  promiseArray.push(modelType.validate(
                    model.id, {
                      detail: [{ loc: [attrBranch], msg: val.msg }]
                    }
                  ))
                }
              }
            })
          ))
        }
        return Promise.all(promiseArray)
      }

      Model.prototype.validate = function (data, options) {
        const modelType = Object.getPrototypeOf(this).constructor
        return modelType.validate(this.id, data, options)
      }

      Model.prototype.clearErrorForField = function (field) {
        const modelType = Object.getPrototypeOf(this).constructor
        return modelType.clearErrorForField(this.id, field)
      }

      Model.prototype.clearErrors = function (associations = []) {
        const modelType = Object.getPrototypeOf(this).constructor
        return modelType.clearErrors(this.id, associations)
      }
    }
  }
}
export default ModelValidations

// FIELD VALIDATIONS CODE

const fieldValidator = {
  computed: {
    itemErrors () {
      return this.item.errors
    },
    itemHasErrors () {
      return Object.keys(this.itemErrors).length > 0
    },
    itemRootErrors () {
      return this.itemErrors.__root__
    },
    itemHasRootErrors () {
      return !!this.itemRootErrors
    }
  },
  methods: {
    fieldHasErrors (field) {
      return !!this.item.errors[field]
    },
    mapErrors () {
      if (this.$el && this.$el.querySelectorAll) {
        this.$el.querySelectorAll('input, select, textarea').forEach((el) => {
          const name = el.name
          const error = this.itemErrors[name]

          if (error) {
            el.setCustomValidity(error)
          }
        })
      }
    }
  },
  mounted () {
    this.mapErrors()
  }
}

export const FieldValidatorNoItem = { ...fieldValidator }

fieldValidator.props = ['item']

// must be exported after FieldValidatorNoItem
export const FieldValidator = fieldValidator
