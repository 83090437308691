<template>

  <div class="fI">

    <div v-if='product_mode == "bos"'>

      <div class="row row-detail">
        <div class="col-4 cInfoHeadOverview trans" :title="$gettext('The total hashrate of all miners combined.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR</translate></div>
        <div class="col-4 cInfoHeadOverview trans" :title="$gettext('Average fan speed of all miners combined.')"><i class="fas fa-fan fas-small fas-left"></i><translate>FANS</translate></div>
        <div class="col-4 cInfoHeadOverview trans" :title="$gettext('Average temperature of all miners combined.')" style="border-right:none!important;"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TEMP</translate></div>
      </div>
      <div class="row row-detail">
        <div class="col-4 cInfoBodyOverview hrReported" style="padding:0;"><b>{{ latestFarmStat.hashrate_total }} {{ latestFarmStat.hashrate_unit }}</b></div>
        <div :class="'col-4 cInfoBodyOverview ' + fanspeedIndication">{{ (latestFarmStat.fanspeedAvg > 100) ? 100 : latestFarmStat.fanspeedAvg.toFixed(0) }}%</div>
        <div :class="'col-4 cInfoBodyOverview ' + temperatureIndication">{{ (latestFarmStat.temperatureAvg >= 0) ? latestFarmStat.temperatureAvg.toFixed(0) : 0 }}°C</div>
      </div>

    </div>

    <!-- PRO -->

    <div v-else>

      <transition-group name="component-fade">

        <div key="data01" v-if="show" style="width:100%; float:left; position: relative;">

          <div class="row row-detail">
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR RT</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Number of total asics and online asics.')"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TEMP</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Average temperature of all miners combined.')"><i class="fas fa-fan fas-small fas-left"></i><translate>FANS</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Average fan speed of all miners combined.')" style="border-right:none!important;"><i class="fas fa-server fas-small fas-left"></i><translate>ASICS</translate></div>
          </div>
          <div class="row row-detail">
            <div class="col-3 cInfoBodyOverviewPro" style="padding:0;"><b>{{ latestFarmStat.hashrate_total }} {{ latestFarmStat.hashrate_unit }}</b></div>
            <div :class="'col-3 cInfoBodyOverviewPro ' + temperatureIndication">{{ (latestFarmStat.temperatureAvg >= 0) ? latestFarmStat.temperatureAvg.toFixed(0) : 0 }}°C</div>
            <div :class="'col-3 cInfoBodyOverviewPro ' + fanspeedIndication">{{ (latestFarmStat.fanspeedAvg > 100) ? 100 : latestFarmStat.fanspeedAvg.toFixed(0) }}%</div>
            <div :class="'col-3 cInfoBodyOverviewPro ' + onlinecountIndication">{{ latestFarmStat.miner_count_online }}</div>
          </div>

        </div>

        <div key="data02" v-else style="width:100%; float:left; position: relative;">
          <div class="row row-detail">
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the pool.')"><i class="fas fa-bezier-curve fas-small fas-left"></i><translate>POOL</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the pool.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR 01H</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the pool.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR 12H</translate></div>
            <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the pool.')" style="border-right:none!important;"><i class="fas fa-hammer fas-small fas-left"></i><translate>WORKER</translate></div>
          </div>
          <div class="row row-detail">
            <div class="col-3 cInfoBodyOverviewPro" style="padding:0;">
              <span v-if='latestFarmStat.pool_status === 1' class="green" :title="$gettext('All your miners are properly connected to a pool.')"><i class="fas fa-check-circle fas-right" style="padding-left:0px; font-size:1em;"></i></span>
              <span v-else class="red" :title="$gettext('One or more devices have issues with their connection to the pool. This is to be expected during restart of miners for instance (after configuration changes).')"><i class="fas fa-exclamation-triangle fas-right" style="padding-left:0px;font-size:1em;"></i></span>
            </div>
            <div class="col-3 cInfoBodyOverviewPro" style="padding:0;">{{ latestFarmStat && latestFarmStat.pool_hashrate_5m ? latestFarmStat.pool_hashrate_5m + ' ' + latestFarmStat.hashrate_unit : 'n/a' }}</div>
            <div class="col-3 cInfoBodyOverviewPro" style="padding:0;">{{ latestFarmStat && latestFarmStat.pool_hashrate_12h ? latestFarmStat.pool_hashrate_12h + ' ' + latestFarmStat.hashrate_unit : 'n/a' }}</div>
            <div :class="'col-3 cInfoBodyOverviewPro ' + workercountIndication">{{ latestFarmStat && latestFarmStat.pool_worker_count ? latestFarmStat.pool_worker_count : 'n/a' }}</div>
          </div>

        </div>

      </transition-group>

    </div>

  </div>

</template>

<script>
export default {
  name: 'overview-stats',
  props: ['latestFarmStat', 'product_mode'],
  data () {
    return {
      show: true,
      intervalId: null,
      timerRunning: false
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    fadeData () {
      if (this.timerRunning === false) {
        this.intervalId = setInterval(() => {
          this.timerRunning = true
          if (this.show === false) {
            this.show = true
          } else {
            this.show = false
          }
        }, 8000)
      }
    }
  },
  computed: {
    onlinecountIndication () {
      const onlineCount = (this.latestFarmStat ? this.latestFarmStat.miner_count_online : 0)
      const totalCount = (this.latestFarmStat ? this.latestFarmStat.miner_count_total : 0)
      let result = 'red pulsate'
      switch (true) {
        case onlineCount === 0:
          result = 'red pulsate'
          break
        case (onlineCount < totalCount):
          result = 'yellow'
          break
        case onlineCount === totalCount:
          result = 'green'
          break
      }
      return result
    },
    workercountIndication () {
      const workerCount = (this.latestFarmStat && this.latestFarmStat.pool_worker_count ? this.latestFarmStat.pool_worker_count : 0)
      const totalCount = (this.latestFarmStat ? this.latestFarmStat.miner_count_total : 0)
      let result = null
      switch (true) {
        case workerCount === 0:
          result = 'red pulsate'
          break
        case (workerCount < totalCount):
          result = 'yellow'
          break
        case workerCount === totalCount:
          result = 'green'
          break
      }
      return result
    },
    fanspeedIndicationBraiinsStyle () {
      var fanSpeed = this.latestFarmStat.fanspeedAvg
      if (!fanSpeed || fanSpeed < 1) {
        fanSpeed = 0
      }
      let result = null
      switch (true) {
        case fanSpeed >= 85:
          result = 'red pulsate'
          break
        case (fanSpeed < 85 && fanSpeed >= 65):
          result = 'fans_01'
          break
        case (fanSpeed < 65 && fanSpeed >= 1):
          result = 'fans_02'
          break
        case fanSpeed < 1:
          result = 'red pulsate'
          break
      }
      return result
    },
    fanspeedIndication () {
      const fanSpeed = this.latestFarmStat.fanspeed_avg
      let result = null
      switch (true) {
        case fanSpeed >= 85:
          result = 'red'
          break
        case (fanSpeed < 85 && fanSpeed >= 65):
          result = 'fans_01'
          break
        case (fanSpeed < 65 && fanSpeed >= 1):
          result = 'fans_02'
          break
        case fanSpeed < 1:
          result = 'red'
          break
      }
      return result
    },
    temperatureIndication () {
      const temp = this.latestFarmStat.temperature_avg
      let result = null
      switch (true) {
        case temp > 92:
          result = 'red'
          break
        case temp >= 85:
          result = 'temp_02'
          break
        case (temp < 85 && temp >= 65):
          result = 'temp_01'
          break
        case temp < 65:
          result = 'red'
          break
      }
      return result
    },
    poolIndication () {
      const poolStatus = this.latestFarmStat.pool_status
      let result = 'red'
      switch (true) {
        case poolStatus === 0:
          result = 'red'
          break
        case poolStatus === 1:
          result = 'green'
          break
      }
      return result
    }
  },
  mounted () {
    this.fadeData()
  }
}
</script>
