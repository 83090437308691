<template>

  <div :class="'container-fluid overview-page fI mobile_' + isMobile">

    <!-- CONTENT : HEADER -->

    <FarmgodHeader>
      <div class='row'>
        <div class='col-sm-12'>
          <Logo v-bind:link="'overview'" v-bind:title="'Powered by FarmGod.io'"/>
        </div>
      </div>
      <div class='row infoBox'>
        <NavBar />
      </div>
    </FarmgodHeader>

    <!-- CONTENT : CORE -->

    <div v-if="showCards" class='row farmOverviewRow'>

      <FarmCard v-for="(farm, index) in allFarms"
        :id="farm.id"
        :name="farm.name"
        :deviceset_id="farm.deviceset_id"
        :is_active="farm.is_active"
        :price_kwh="farm.price_kwh"
        :currency="farm.currency"
        :worker_mode="farm.worker_mode"
        :owners="farm.owners"
        :key="index">
      </FarmCard>

      <FarmCreate :key='showFarmCreate' :showCards='showCards' :show='showFarmCreate' />

      <StatsCard v-if='product_mode == "pro" && showCards'/>

    </div>

    <div v-else class='row farmOverviewRow'>

      <FarmCard v-for="(farm, index) in allFarms"
        :id="farm.id"
        :name="farm.name"
        :deviceset_id="farm.deviceset_id"
        :is_active="farm.is_active"
        :price_kwh="farm.price_kwh"
        :currency="farm.currency"
        :worker_mode="farm.worker_mode"
        :owners="farm.owners"
        :key="index">
      </FarmCard>

      <FarmCreate :key='showFarmCreate' :showCards='showCards' :show='showFarmCreate' />

    </div>

    <!-- CONTENT : FOOTER -->

    <FarmgodFooter>
      <LoggedInFooter/>
    </FarmgodFooter>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { FARM__ALL_WITH_STATS__FOR_USER, OVERVIEW__LIVE_STATS__TOGGLE } from '../store/actions.type'
import FarmCard from '@/components/views/Overview/FarmCard'

import Farm from '@/models/farm'

import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import NavBar from '@/components/views/Overview/NavBar'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedInFooter from '@/components/layout/Footers/LoggedIn'
import FarmCreate from '@/components/views/Overview/FarmCreate'
import StatsCard from '@/components/views/Overview/StatsCard'

import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'overview',
  components: { FarmCreate, FarmgodHeader, Logo, NavBar, FarmCard, FarmgodFooter, LoggedInFooter, StatsCard },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      intervalId: null,
      showCards: false,
      shouldReceiveLive: true,
      showFarmCreate: false
    }
  },
  methods: {
    async initFirstCard () {
      this.showCards = true
    },
    async reloadFarms () {
      this.$store.dispatch(FARM__ALL_WITH_STATS__FOR_USER)
    },
    removeFarm (id) {
      Farm.delete(id)
    }
  },
  beforeCreate () {
    Farm.deleteAll()
  },
  created () {
    this.$store.dispatch(OVERVIEW__LIVE_STATS__TOGGLE, true)
  },
  computed: {
    ...mapGetters(['newFarm']),
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    allFarms () {
      return Farm.query().where({ visible: true }).with('stats').get()
    }
  },
  mounted () {
    this.$store.dispatch(FARM__ALL_WITH_STATS__FOR_USER)
      .then(() => {
        if (this.allFarms && this.allFarms.length > 0) {
          this.showCards = true
        } else {
          this.showCards = false
          this.showFarmCreate = true
        }
      })
      .catch((error) => {
        console.error('Error fetching farms:', error)
      })
  },
  beforeDestroy () {
    this.$store.dispatch(OVERVIEW__LIVE_STATS__TOGGLE, false)
  }
}
</script>
