<template>

  <div class="farmCard col-lg-2dot4 col-md-3 col-sm-6 fI-slow">

    <div v-if="showCards" class="blur">
        <div id='add-farm-control--show-cards' class="container_wrapper_overview evenRow addFarmBox trans" @click.prevent="addFarm()">
          <div class="container_body_overview">
            <div class="addFarmSymbol pulsate fas fas-small fas-left" id='add-farm-control'>
              <font-awesome-icon :icon="['fas', 'plus-square']" />
              <div style="font-size: 0.15em; padding-top: 5px;">FARM CREATOR</div>
            </div>
          </div>
        </div>
    </div>

    <div v-else class="blur">
      <div id='add-farm-control--hide-cards' class="container_wrapper_overview evenRow addFarmBox trans" @click.prevent="openWelcome()">
        <div class="container_body_overview">
          <div class="addFarmSymbol pulsate fas fas-small fas-left" id='add-farm-control'>
              <font-awesome-icon :icon="['fas', 'plus-square']" />
              <div style="font-size: 0.15em; padding-top: 5px;">FARM CREATOR</div>
            </div>
        </div>
      </div>
    </div>

    <!-- MODAL : FIRST FARM INTRO -->

    <Modal ref="addFarmModal">

      <template v-slot:header>

        <!-- MODAL : FIRST FARM INTRO header -->

        <h5 v-if='createFarmWizard == "welcome"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_11">WELCOME</translate> <small class="color01">_ <translate key="afmh_12">TO THE FARM CREATOR</translate></small></h5>

        <!-- MODAL : ADDFARM : STEP 01 header -->

        <h5 v-if='createFarmWizard == "addFarm" && product_mode == "pro"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_21">STEP 1/3</translate> <small class="color01">_ <translate key="afmh_22">DEVICE-SET NAME</translate></small></h5>
        <h5 v-if='createFarmWizard == "addFarm" && product_mode == "cloud"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_21">DEVICE-SET</translate> <small class="color01">_ <translate key="afmh_22">NAME</translate></small></h5>

        <!-- MODAL : ADDFARM : STEP 02 header -->

        <h5 v-if='createFarmWizard == "addPool"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_31">STEP 2/3</translate> <small class="color01">_ <translate key="afmh_32">ADD A POOL</translate></small></h5>

        <!-- MODAL : ADDFARM : STEP 03 header -->

        <h5 v-if='createFarmWizard == "setWorkerNameMode"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_41">STEP 3/3</translate> <small class="color01">_ <translate key="afmh_42">SET WORKER NAME MODE</translate></small></h5>

        <!-- MODAL : ADDFARM : STEP 04 header -->

        <h5 v-if='createFarmWizard == "showId"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_41">COPY</translate> <small class="color01">_ <translate key="afmh_42">YOUR DEVICE-SET-ID</translate></small></h5>

        <!-- MODAL : ADDFARM : STEP 05 header -->

        <h5 v-if='createFarmWizard == "farmCreationDone"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_51">DONE</translate> <small class="color01">_ <translate key="afmh_52">DEVICE-SET CREATED</translate></small></h5>

        <!-- MODAL : FIRST FARM OUTRO header -->

        <h5 v-if='createFarmWizard == "addFarmNote"' class="modal-title"><font-awesome-icon icon='server'/> <translate key="afmh_61">NEXT</translate> <small class="color01">_ <translate key="afmh_62">UP</translate></small></h5>

      </template>

      <template v-slot:body>

        <!-- MODAL : FIRST FARM INTRO body -->

        <div v-if='createFarmWizard == "welcome"'>
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -20px;margin-bottom: -15px;">
            <div class="color03" style="letter-spacing: 1px; font-size: 1.1em; margin-top: 10px; margin-bottom:15px;"><i class="fab fas-xsBtn fa-buromobelexperte fas-left"></i>{{ $gettext('READY TO CREATE YOUR FIRST DEVICE-SET?') }}</div>
          </div>
          <div class="fI-slow grey" v-if='product_mode == "cloud"'>
            <div v-translate>
              All it initially takes to get started, is a descriptive <b>name</b> for your new group of devices.
            </div>
            <div v-translate>
              Once created, the <b>CLOUD.Kollektor</b> tool is used to connect your ASICs with the 'Device-Set'.
            </div>
          </div>
          <div class="fI-slow grey" v-else>
            <div v-translate>
              All it initially takes to get started, is a <b>name</b> for your device-set and one valid <b>pool</b>.
            </div>
            <div v-translate>
              <b>Hint:</b> Once devices are added to the set, more options will become available.
            </div>
          </div>
        </div>

        <!-- MODAL : ADDFARM : STEP 01 body -->

        <div v-if='item && createFarmWizard == "addFarm"' class="fI-slow">
          <div v-if='itemHasRootErrors' class='item-root-errors alert alert-danger'>
            {{ itemRootErrors }}
          </div>

          <input name='id' type='hidden' v-model='item.id' />
          <FormulateInput
            :errors='itemErrors.name'
            ref='farmNameInput'
            @validation="farmNameValidation = $event"
            :label="$gettext('NAME')"
            :tooltip="$gettext('Enter a name for your set of devices.')"
            name='name'
            type='text'
            :validation="[['bail'], ['min', 2, 'length'],['max', 16, 'length'],['matches', /^[0-9a-zA-Z_]+$/]]"
            error-behavior="blur"
            :validation-messages="{ matches: 'Device-set names must be alpha numeric and seperated by underscores only' }"
            v-model='item.name'
            autocomplete="off"
            placeholder='eg. x21'
            style="margin-bottom: -10px;"
          />
        </div>

        <!-- MODAL : ADDFARM : STEP 02 body -->

        <PoolConfigFields ref='poolConfigFormComp' :key='validationCacheKey("poolConfig", item.pool_config)' v-if='createFarmWizard == "addPool"' :item='item.pool_config' :updatePoolConfigValidation='updatePoolConfigValidation' style="margin-bottom: -10px;"/>

        <!-- MODAL : ADDFARM : STEP 03 body -->

        <div :key='createCacheKey()' v-if='createFarmWizard == "setWorkerNameMode"' id="setWorkerNameMode" class="fI-slow">
          <fieldset class="configGrpFarmEdit" style="margin-top:0px;">
            <FormulateInput
                v-if="workerModeDelimiter == 0"
                :label="$gettext('WORKER NAME MODE')"
                type='select'
                :tooltip="$gettext('Choose between having one worker name for all devices (device-set name) or having individual worker names per device (eg. deviceset_ip3_ip4).')"
                :placeholder="$gettext('Please choose...')"
                :options="{
                  0: 'SINGLE (USE DEVICE-SET NAME FOR ALL DEVICES)',
                  32768: 'PER DEVICE (IP4)',
                  49152: 'PER DEVICE (IP3xIP4)',
                  57344: 'PER DEVICE (IP2xIP3xIP4)',
                  32784: 'PER DEVICE (DEVICESETxIP4)',
                  49168: 'PER DEVICE (DEVICESETxIP3xIP4)',
                  57360: 'PER DEVICE (DEVICESETxIP2xIP3xIP4)',
                  65536: 'PER DEVICE (DEVICE-ID / MANUAL OVERRIDE)'
                }"
                v-model='workerMode'
                name='workerMode'
              />
            <FormulateInput
                v-if="workerModeDelimiter == 1"
                :label="$gettext('WORKER NAME MODE')"
                type='select'
                :placeholder="$gettext('Please choose...')"
                :tooltip="$gettext('Choose between having one worker name for all devices (device-set name) or having individual worker names per device (eg. deviceset_ip3_ip4).')"
                :options="{
                  0: 'SINGLE (USE DEVICE-SET NAME FOR ALL DEVICES)',
                  1081344: 'PER DEVICE (IP4)',
                  1097728: 'PER DEVICE (IP3_IP4)',
                  1105920: 'PER DEVICE (IP2_IP3_IP4)',
                  1081360: 'PER DEVICE (DEVICESET_IP4)',
                  1097744: 'PER DEVICE (DEVICESET_IP3_IP4)',
                  1105936: 'PER DEVICE (DEVICESET_IP2_IP3_IP4)',
                  1114112: 'PER DEVICE (DEVICE-ID / MANUAL OVERRIDE)'
                }"
                v-model='workerMode'
                name='workerMode'
              />
            <FormulateInput
                :label="$gettext('DELIMITER')"
                :tooltip="$gettext('Choose between using \'x\' (default) or \'_\' as delimiter for worker name assembly.')"
                type='select'
                v-model='workerModeDelimiter'
                v-on:change='inputWatcher'
                :options='{ 0: "x", 1: "_" }'
                />
            <div class='form-group fI' v-if="workerMode != 65536 && workerMode != 1114112" style="margin-bottom:-10px;">
              <div v-if="workerMode != 'null'">
                <FormulateInput key="fs_wm_preview1" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 0" disabled='disabled' type='text' :value="item.name.split('_').join('x')" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview2" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 32768" disabled='disabled' type='text' value="219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview3" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 32784" disabled='disabled' type='text' :value="item.name.split(' ').join('x').split('_').join('x') + 'x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview4" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 49152" disabled='disabled' type='text' value="1x219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview5" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 49168" disabled='disabled' type='text' :value="item.name.split(' ').join('x').split('_').join('x') + 'x1x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview6" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 57344" disabled='disabled' type='text' value="5x1x219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview7" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 57360" disabled='disabled' type='text' :value="item.name.split(' ').join('x').split('_').join('x') + 'x5x1x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview8" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1048576" disabled='disabled' type='text' :value="item.name.split(' ').join('_')" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview9" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1081344" disabled='disabled' type='text' value="219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview10" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1081360" disabled='disabled' type='text' :value="item.name.split(' ').join('_') + '_219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview11" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1097728" disabled='disabled' type='text' value="1_219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview12" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1097744" disabled='disabled' type='text' :value="item.name.split(' ').join('_') + '_1_219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview13" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1105920" disabled='disabled' type='text' value="5_1_219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview14" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1105936" disabled='disabled' type='text' :value="item.name.split(' ').join('_') + '_5_1_219'" style="opacity: 0.55;" />
              </div>
            </div>
            <div class='form-group fI' v-if="workerMode == 65536 || workerMode == 1114112" style="margin-bottom:-5px;">
              <div class="fI-slow grey" style="width: 70%;margin:auto;" v-translate>
                To <b>manually override</b> the worker name of devices, go to <b>Miners</b> and click the <i class="fas fas-xsBtn fa-cog"></i> icon next to the worker name in the list.
              </div>
            </div>
            <div class='form-group fI' v-if="workerModeDelimiter == 1 && workerMode == 'null'" style="margin-bottom:-5px;">
              <div class="fI-slow grey" style="width: 70%;margin:auto;" v-translate>
                <b>ATTENTION:</b> Using _ as delimiter could lead to <b>pool connection issues</b> (eg. AntPool). Use with caution!
              </div>
            </div>
          </fieldset>
        </div>

        <!-- MODAL : ADDFARM : STEP 04 body -->

        <div :key='createCacheKey()' v-if='createFarmWizard == "showId"' id="showFarmId" class="fI-slow" style="margin-bottom: 0px;">
          <FormulateInput
              :label="$gettext('DEVICE-SET-ID')"
              :tooltip="$gettext('This is your Device-Set ID.')"
              type='text'
              v-model='item.deviceset_id'
              :placeholder="$gettext('Waiting for server... Please wait!')"
              disabled='disabled'
              name='deviceset_id'
          />
          <div class="canvas-wrapper grey" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: 0px;margin-bottom: -15px;">
              <span v-translate>Copy the <b>Device-Set iD</b> to the clipboard and use it with the <b>CLOUD.Kollektor</b>.</span>
            </div>
        </div>

        <!-- MODAL : ADDFARM : STEP 05 body -->

        <div v-if='createFarmWizard == "farmCreationDone"' id="farmCreationDone" class="fI-slow">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 4em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px; color: #13A454;"> {{ $gettext('DEVICE-SET SUCCESSFULLY CREATED') }} </div>
          </div>
        </div>

        <!-- MODAL : FIRST FARM OUTRO body -->

        <form v-if='createFarmWizard == "addFarmNote" && product_mode == "pro"' id="addFarmNote">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -20px;margin-bottom: -15px;">
              <div class="color03" style="letter-spacing: 1px; font-size: 1.1em; margin-top: 10px;"><i class="fas fas-xsBtn fa-link fas-left"></i>{{ $gettext('ADD DEVICES TO THE DEVICE-SET') }}</div>
            </div>
            <div class="canvas-wrapper grey" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <span v-translate>To connect your devices, you have to <b>import</b> them. Once done it should take no more than a <b>couple of seconds</b> for them to show up.</span>
            </div>
          </div>
        </form>
        <form v-if='createFarmWizard == "addFarmNote" && product_mode == "cloud"' id="addFarmNote">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -20px;margin-bottom: -15px;">
              <div class="color03" style="letter-spacing: 1px; font-size: 1.1em; margin-top: 10px;"><i class="fas fas-xsBtn fa-link fas-left"></i>{{ $gettext('ADD DEVICES TO THE DEVICE-SET') }}</div>
            </div>
            <div class="canvas-wrapper grey" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <span v-translate>To connect your asics to the newly created 'Device-Set', you have to scan for them using the <b>CLOUD.Kollektor</b> tool. Click 'NEXT' to proceed...</span>
            </div>
          </div>
        </form>

      </template>

      <template v-slot:footer>

        <!-- MODAL : ADDFARM : WELCOME footer -->

        <div v-if='createFarmWizard == "welcome"' style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-primary' ref='welcome-btn-next' @click.prevent="addFarmFromWelcome()"><translate key="afmf_1">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>

        <!-- MODAL : ADDFARM : STEP 01 footer -->

        <div v-if='createFarmWizard == "addFarm" && product_mode == "pro"' style="display:inline-flex;width:80%;">
          <button v-if='farmNameIsValid()' type='submit' ref='addFarm-btn-next' class='next-btn btn btn-success addFarm-btn-next trans' @click.prevent="addPool()"><translate key="afmf_21">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
          <button v-else class='next-btn btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Please enter a valid name first...')"><translate key="afmf_22">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>
        <div v-if='createFarmWizard == "addFarm" && product_mode == "cloud"' style="display:inline-flex;width:80%;">
          <button v-if='farmNameIsValid()' type='submit' ref='addFarm-btn-next' class='next-btn btn btn-success addFarm-btn-next trans' @click.prevent="createFarm('cloud');"><translate key="afmf_21">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
          <button v-else class='next-btn btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Please enter a valid name first...')"><translate key="afmf_22">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>

        <!-- MODAL : ADDFARM : STEP 02 footer -->

        <div v-if='createFarmWizard == "addPool"' style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' @click.prevent="backToAddFarm()"><i class="fas fas-xsBtn fa-angle-double-left fas-left"></i> <translate key="afmf_31">BACK</translate></button>
          <button name='pool-config-submit-button' ref='addPool-btn-next' v-if='poolConfigIsValid()' :key='poolConfigValid' class='next-btn btn btn-success trans' @click.prevent="setWorkerNameMode()"><translate key="afmf_32">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
          <button v-else class='next-btn btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Please supply a valid pool first...')"><translate key="afmf_33">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>

        <!-- MODAL : ADDFARM : STEP 03 footer -->

        <div v-if='createFarmWizard == "setWorkerNameMode"' style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-success' ref='setWorkerNameMode-btn-next' @click.prevent="createFarm();"><translate key="afmf_41">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>

        <!-- MODAL : ADDFARM : STEP 04 footer -->

        <div v-if='createFarmWizard == "showId"' style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-success' ref='showId-btn-next' @click.prevent="farmCreationDone();"><i class="fas fas-xsBtn fa-copy fas-left"></i><translate key="afmf_41">COPY THE 'ID' TO THE CLIPBOARD</translate></button>
        </div>

        <!-- MODAL : ADDFARM : STEP 05 footer -->

        <div v-if='createFarmWizard == "farmCreationDone" && product_mode == "pro"' style="display:inline-flex;width:80%;">
          <button v-if="showCards" ref='farmCreationDone-btn-returnToOverview' class='next-btn btn btn-secondary' @click.prevent="returnToOverview()"><i class="fa fas-xsBtn fa-th-large fas-left"></i> <translate key="afmf_51">RETURN TO OVERVIEW</translate></button>
          <button v-if="showCards" class='next-btn btn btn-success' @click.prevent="importDevices('pro')"><i class="fas fas-xsBtn fa-plus-square fas-left"></i>IMPORT DEVICES</button>
          <button v-else class='next-btn btn btn-secondary' ref='farmCreationDone-btn-addFarmNote' @click.prevent="addFarmNote()"><translate key="afmf_52">NEXT</translate> <i class="fas fas-xsBtn fa-angle-double-right fas-right"></i></button>
        </div>
        <div v-if='createFarmWizard == "farmCreationDone" && product_mode == "cloud"' style="display:inline-flex;width:80%;">
          <!--button ref='farmCreationDone-btn-returnToOverview' class='next-btn btn btn-success' @click.prevent="showId();copy();"><i class="fa fas-xsBtn fa-copy fas-left"></i> <translate key="afmf_51">GET THE 'DEVICE-SET' ID</translate></button-->
          <button class='next-btn btn btn-success' @click.prevent="importDevices('cloud')"><i class="fas fas-xsBtn fa-download fas-left"></i>GET THE 'KOLLEKTOR'</button>
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="returnToOverview()"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>

        <!-- MODAL : FIRST FARM OUTRO footer -->

        <div v-if='createFarmWizard == "addFarmNote"' style="display:inline-flex;width:80%;">
          <button form='addFarmNote' class='next-btn btn btn-primary' ref='addFarmNote-btn-returnToOverview' @click.prevent="returnToOverview()"><i class="fas fas-xsBtn fa-th-large fas-left"></i><translate key="afmf_61">RETURN TO OVERVIEW</translate></button>
          <button v-if='product_mode == "pro"' class='next-btn btn btn-success' @click.prevent="importDevices('pro')"><i class="fas fas-xsBtn fa-plus-square fas-left"></i>IMPORT DEVICES</button>
          <button v-if='product_mode == "cloud"' class='next-btn btn btn-success' @click.prevent="importDevices('cloud')"><i class="fas fas-xsBtn fa-angle-double-right fas-left"></i>NEXT</button>
        </div>

      </template>

    </Modal>

    <!-- COMPONENT : ADD DEVICES TO DEVICE-SET -->

    <DeviceImporter ref="DeviceImporter" v-if='product_mode == "pro"'/>

    <DeviceImporterCloud ref="DeviceImporter" v-if='product_mode == "cloud"'/>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  validationCacheKey as vCacheKey,
  FieldValidatorNoItem as Validations
} from '@/plugins/vuex-orm/validations'

import Modal from '@/components/widgets/Modal.vue'
import PoolConfigFields from '@/components/views/Overview/FarmCreate/PoolConfigFields'
import DeviceImporter from '@/components/widgets/DeviceImporter.vue'
import DeviceImporterCloud from '@/components/widgets/DeviceImporterCloud.vue'
import { FARM__CREATE, NEW_FARM__SPAWN, FARM__CREATE_PROGRESS } from '@/store/actions.type'
import { PRODUCT_MODE } from '@/common/config'

export default {
  extends: Validations,
  name: 'farm-create',
  props: ['showCards', 'createFarmFunc', 'newFarm', 'show'],
  components: { Modal, PoolConfigFields, DeviceImporter, DeviceImporterCloud },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      farmNameValidation: {},
      poolConfigValid: false,
      poolUrlValid: false,
      poolUsernameValid: false,
      workerMode: 49168,
      workerModeDelimiter: 0
    }
  },
  computed: {
    ...mapGetters({ item: 'newFarm', createFarmWizard: 'createFarmWizard' })
  },
  watch: {
    item: function (newItem, oldItem) {
      if (newItem && Object.keys(newItem.errors).length > 0) {
        this.$store.dispatch(FARM__CREATE_PROGRESS, 'addFarm').then(() => {
          this.mapErrors()
        })
      } else if (newItem && Object.keys(newItem.pool_config.errors).length > 0) {
        this.$store.dispatch(FARM__CREATE_PROGRESS, 'addPool').then(() => {
          this.mapErrors()
        })
      }
    }
  },
  created () {
    if (this.show) {
      this.openWelcome()
    }
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        if (this.createFarmWizard === 'welcome') {
          this.$refs['welcome-btn-next'].click()
        }
        if (this.createFarmWizard === 'addFarm') {
          if (this.farmNameIsValid()) {
            this.$refs['addFarm-btn-next'].click()
          }
        }
        if (this.createFarmWizard === 'addPool') {
          if (this.poolConfigIsValid()) {
            this.$refs['addPool-btn-next'].click()
          }
        }
        if (this.createFarmWizard === 'setWorkerNameMode') {
          this.$refs['setWorkerNameMode-btn-next'].click()
        }
        if (this.createFarmWizard === 'showId') {
          this.$refs['showId-btn-next'].click()
        }
        if (this.createFarmWizard === 'farmCreationDone') {
          if (this.showCards) {
            this.$refs['farmCreationDone-btn-returnToOverview'].click()
          } else {
            this.$refs['farmCreationDone-btn-addFarmNote'].click()
          }
        }
        if (this.createFarmWizard === 'addFarmNote') {
          this.$refs['addFarmNote-btn-returnToOverview'].click()
        }
      }
    })
  },
  methods: {
    inputWatcher () {
      this.workerMode = 'null'
    },
    updatePoolConfigValidation (fieldEvent) {
      if (fieldEvent.url) {
        this.poolUrlValid = !fieldEvent.url.hasErrors
      }
      if (fieldEvent.username) {
        this.poolUsernameValid = !fieldEvent.username.hasErrors
      }

      this.poolConfigValid = (this.poolUrlValid && this.poolUsernameValid)
    },
    farmNameIsValid () {
      return !this.farmNameValidation.hasErrors
    },
    poolConfigIsValid () {
      return this.poolConfigValid
    },
    validationCacheKey (model, values) {
      return vCacheKey(model, values)
    },
    createFarm (mode) {
      if (this.poolConfigValid && mode !== 'cloud') {
        this.item.pool_config.url = this.formattedUrl()
        this.item.workerMode = this.workerMode

        this.$store.dispatch(FARM__CREATE, this.item)
          .then(() => {
            this.farmCreationDone()
          })
          .catch(() => {
            this.mapErrors()
          })
      } else if (mode === 'cloud') {
        this.item.pool_config.url = 'https://some.url'
        this.item.pool_config.username = 'default'
        this.item.workerMode = this.workerMode

        this.$store.dispatch(FARM__CREATE, this.item)
          .then(() => {
            this.farmCreationDone()
          })
          .catch(() => {
            this.mapErrors()
          })
      }
    },
    createCacheKey () {
      return JSON.stringify((this.item ? this.item.id : null))
    },
    openWelcome () {
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'welcome').then(() => {
        this.$store.dispatch(NEW_FARM__SPAWN)
        this.$refs.addFarmModal.openModal()
      })
    },
    addFarmFromWelcome () {
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'addFarm')
    },
    addFarm () {
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'addFarm').then(() => {
        this.$store.dispatch(NEW_FARM__SPAWN)
        this.$refs.addFarmModal.openModal()
      })
    },
    addPool () {
      if (this.$refs.farmNameInput.isValid) {
        this.$store.dispatch(FARM__CREATE_PROGRESS, 'addPool')
      }
    },
    setWorkerNameMode () {
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'setWorkerNameMode')
    },
    backToAddFarm () {
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'addFarm')
    },
    showId () {
      this.$parent.reloadFarms()
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'showId')
    },
    farmCreationDone () {
      // this.copy()
      this.$parent.initFirstCard()
      this.$parent.reloadFarms()
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'farmCreationDone')
    },
    addFarmNote () {
      this.$parent.initFirstCard()
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'addFarmNote')
    },
    returnToOverview () {
      this.$parent.initFirstCard()
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'closed')
      this.$refs.addFarmModal.closeModal()
      this.$store.dispatch(NEW_FARM__SPAWN)
    },
    importDevices (mode) {
      this.$parent.initFirstCard()
      this.$store.dispatch(FARM__CREATE_PROGRESS, 'closed')
      this.$refs.addFarmModal.closeModal()
      this.$store.dispatch(NEW_FARM__SPAWN)
      // SHOW IMPORTER
      if (mode === 'cloud') {
        this.$refs.DeviceImporter.$refs.infoModal.openModal(this.item.deviceset_id)
      } else {
        this.$refs.DeviceImporter.$refs.addDevicesModal.openModal(this.item.deviceset_id)
      }
    },
    formattedUrl () {
      var theUrl = this.item.pool_config.url
      return theUrl.charAt(0).toLowerCase() + theUrl.substring(1)
    },
    async copy () {
      await navigator.clipboard.writeText(this.item.deviceset_id)
    }
  }
}
</script>
