<template>

  <div class="col-md-12">

    <div class="menu fI-slow">

      <!-- METRiCS -->

      <a :href="metrics" target="_blank" :title="$gettext('Show detailed metrics')">
        <span v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-chart-bar fas-left"></i><translate style="color:inherit;">SHOW DETAILED METRICS</translate></span>
        <span v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-chart-bar fas-left"></i></span>
      </a>

      <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

      <!-- KOLLEKTOR -->

      <span v-if="product_mode === 'cloud'">

        <span @click="$refs.DeviceImporter.$refs.infoModal.openModal();" v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-download fas-left"></i><translate style="color:inherit;">CONNECT YOUR ASICs</translate></span>
        <span @click="$refs.DeviceImporter.$refs.infoModal.openModal();" v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-download fas-left"></i></span>

        <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

      </span>

      <!-- ACCOUNT -->

      <span @click="$refs.UserSettings.$refs.editUser.openModal();" v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-user-cog fas-left"></i><translate style="color:inherit;">ACCOUNT</translate></span>
      <span @click="$refs.UserSettings.$refs.editUser.openModal();" v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-cog fas-left"></i></span>

      <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

      <!-- SETTiNGS -->

      <span @click="$refs.GlobalSettings.$refs.editGlobals.openModal();" v-if='!isMobile()' style="margin-left: 5px; margin-right: 5px;" class="trans"><i class="fas fa-user-cog fas-left"></i><translate style="color:inherit;">SETTINGS</translate></span>
      <span @click="$refs.GlobalSettings.$refs.editGlobals.openModal();" v-else style="margin-left: 5px; opacity:0.7; margin-top: -3px; font-size: 1.1em;" class="trans"><i class="fas fa-cog fas-left"></i></span>

      <span style="color:#3c3c3c!important;cursor:text!important;">&nbsp;\\&nbsp;</span>

      <!-- LOGOUT -->

      <a @click="logout" id="menuLogout" style="margin-left: 5px; opacity:0.8; margin-top: -3px; font-size: 1.1em;" class="trans" :title="$gettext('Logout')"><i class="fas fa-sign-out-alt fas-left"></i></a>

      <!-- APi -->

      <div v-if='!isMobile()' class="social-wrapper" style="margin:1px 0 0 0; float: right; font-size: 0.95em;">
        <a :href="api_docs" target="_blank" :title="$gettext('View the API-Documentation')">
          <span style="margin-left: 5px; margin-right: 5px; color: #424242;" class="trans"><i class="fas fa-code fas-left"></i><translate style="color:inherit;">API</translate></span>
        </a>
      </div>

    </div>

    <UserSettings ref="UserSettings" />

    <GlobalSettings ref="GlobalSettings" />

    <DeviceImporter ref="DeviceImporter" v-if="product_mode === 'cloud'" />

  </div>

</template>

<script>
import { AUTH__LOGOUT } from '@/store/actions.type'
import { PRODUCT_MODE, API_PROTO, API_FQDN, METRICS_PROTO, METRICS_FQDN } from '@/common/config'
import UserSettings from '@/components/widgets/UserSettings.vue'
import GlobalSettings from '@/components/widgets/GlobalSettings.vue'
import DeviceImporter from '@/components/widgets/DeviceImporterCloud.vue'

export default {
  name: 'logo',
  props: ['farm'],
  components: { UserSettings, GlobalSettings, DeviceImporter },
  created () {
    this.updateMetricsLink()
  },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      api_docs: API_PROTO + '://' + API_FQDN + 'docs',
      metrics: ''
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    logout () {
      this.$store.dispatch(AUTH__LOGOUT).then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    updateMetricsLink () {
      const hostname = window.location.hostname
      const domainParts = hostname.split('.')
      if (domainParts.length > 2) {
        this.metrics = 'metrics'
      } else {
        this.metrics = `${METRICS_PROTO}://${METRICS_FQDN}`
      }
    }
  }
}
</script>
