<template>
  <div>
    <!-- MODAL : GLOBAL SETTiNGS -->
    <Modal ref="editGlobals">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-cog fas-left"></i> <translate>GLOBAL</translate> <small class="color01">_ <translate>SETTINGS</translate></small></h5>
      </template>
      <template v-slot:body>
        <div id="editGlobals" style="margin: -10px 0 -15px 0;">
          <fieldset class="configGrpFarmEdit">
            <label ref='basicConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-bolt"></i><translate>CURTAILMENT</translate></label>
            <FormulateInput
              v-model="curtailment_enabled"
              :label="$gettext('ENABLED')"
              :tooltip="$gettext('Enable/disable global curtailment.')"
              name='enable_curtailment'
              class="enabledCheck"
              type='checkbox'
              label-position="before"
            />
            <FormulateInput
              v-if="curtailment_enabled === 1"
              :label="$gettext('CURTAILMENT ACTIVE')"
              :tooltip="$gettext('Current global curtailment state.')"
              type='text'
              name='state'
              value='NO'
              disabled='disabled'
            />
          </fieldset>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-secondary' @click="$refs.editGlobals.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
          <button class='btn btn-success' title="Save changes." @click="saveSettings"><i class="fas fas-xsBtn fa-save fas-left"></i>SAVE</button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->
    <Modal ref="settingsUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 4em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px; color: #13A454;"> <translate>UPDATE SUCCESSFUL</translate> </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.settingsUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->
    <Modal ref="settingsUpdateError">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
            <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
            <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.settingsUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
import { USER__UPDATE } from '@/store/actions.type'
import Modal from '@/components/widgets/Modal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalSettings',
  components: { Modal },
  data () {
    return {
      curtailment_enabled: 0,
      errorDetail: null,
      errorDetailFull: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    currentUserDebug () {
      return this.currentUser
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler (newValue) {
        if (newValue) {
          this.curtailment_enabled = newValue.curtailment_enabled
        }
      }
    }
  },
  methods: {
    async saveSettings () {
      try {
        await this.$store.dispatch(USER__UPDATE, {
          id: 'me',
          params: { curtailment_enabled: this.curtailment_enabled }
        })
        this.$refs.settingsUpdateSuccess.openModal()
      } catch (err) {
        this.errorDetailFull = JSON.parse(JSON.stringify(err.response.data))
        this.errorDetail = this.errorDetailFull.detail[0].msg
        this.$refs.settingsUpdateError.openModal()
      }
    }
  }
}
</script>
