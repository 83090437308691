<template>
  <div class="small">
    <line-chart :chart-id='"ov_" + farmId' :chart-data="datacollection" :options="options"></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/widgets/charts/Line.js'
import { PRODUCT_MODE } from '@/common/config'

export default {
  components: {
    LineChart
  },
  props: ['farmId', 'unit', 'stats'],
  data () {
    return {
      showChart: false,
      product_mode: PRODUCT_MODE
    }
  },
  computed: {
    allFarmStats () {
      const all = this.stats
      return all
    },
    labels () {
      const labels = this.allFarmStats.map(stat => new Date(stat.created * 1000))
      return labels
    },
    hashrateAvgs () {
      let hashrateAvgs

      if (this.containsZH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit)
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else if (this.containsEH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 1
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else if (this.containsPH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 2
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 3
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      }
      return hashrateAvgs
    },
    hashrateAvgsPool () {
      let hashrateAvgsPool

      if (this.containsZH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit)
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else if (this.containsEH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 1
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else if (this.containsPH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 2
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 3
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      }
      return hashrateAvgsPool
    },
    containsZH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'ZH')
    },
    containsEH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'EH')
    },
    containsPH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'PH')
    },
    hashrateScales () {
      return ['ZH', 'EH', 'PH', 'TH']
    },
    temperatureAvgs () {
      const temperatureAvgs = this.allFarmStats.map(stat => stat.temperatureAvg.toFixed(0))
      return temperatureAvgs
    },
    fanspeedAvgs () {
      return this.allFarmStats.map(stat => stat.fanspeedAvg.toFixed(0))
    },
    hashrateMax () {
      let rate
      if (this.product_mode !== 'pro') {
        rate = Math.round(Math.max.apply(null, this.hashrateAvgs))
      } else {
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool))) {
          rate = Math.round(Math.max.apply(null, this.hashrateAvgs))
        } else {
          rate = Math.round(Math.max.apply(null, this.hashrateAvgsPool))
        }
      }
      return (Math.round((rate * 1.2)) || 5)
    },
    hashrateMin () {
      let rate
      if (this.product_mode !== 'pro') {
        rate = Math.round(Math.min.apply(null, this.hashrateAvgs))
      } else {
        if (Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          rate = Math.round(Math.min.apply(null, this.hashrateAvgs))
        } else {
          rate = Math.round(Math.min.apply(null, this.hashrateAvgsPool))
        }
      }
      return (Math.round((rate * 0.8)) || 0)
    },
    calculateStepSize () {
      let stepSize
      if (this.product_mode !== 'pro') {
        stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.3) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
      } else {
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.3) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) < Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) > Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgsPool)) * 1.3) - Math.round((Math.min.apply(null, this.hashrateAvgsPool) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) > Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.3) - Math.round((Math.min.apply(null, this.hashrateAvgsPool) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) < Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgsPool)) * 1.3) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
        }
      }
      return Math.round(stepSize / 8)
    },
    datacollection () {
      if (this.product_mode !== 'pro') {
        return {
          labels: this.labels,
          datasets: [{
            label: `${this.chartTitles()[0]} (${this.unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgs,
            drawticks: true,
            fill: false,
            backgroundColor: '#15723f',
            borderColor: '#15723f',
            yAxisID: 'y-axis-1',
            borderWidth: 1,
            display: false
          },
          {
            label: `${this.chartTitles()[1]} (%)`,
            lineTension: 0,
            data: this.fanspeedAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#71751d',
            backgroundColor: '#71751d',
            yAxisID: 'y-axis-2',
            borderDash: [2, 2],
            borderWidth: 1,
            display: false
          },
          {
            label: `${this.chartTitles()[2]} (°C)`,
            lineTension: 0,
            data: this.temperatureAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#194698',
            backgroundColor: '#194698',
            yAxisID: 'y-axis-2',
            borderDash: [2, 2],
            borderWidth: 1,
            display: false
          }]
        }
      } else {
        return {
          showChart: false,
          labels: this.labels,
          datasets: [{
            label: `${this.chartTitles()[0]} (${this.unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgs,
            drawticks: true,
            fill: false,
            backgroundColor: '#15723f',
            borderColor: '#15723f',
            yAxisID: 'y-axis-1',
            borderWidth: 1,
            display: false
          },
          {
            label: `${this.chartTitles()[3]} (${this.unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgsPool,
            drawticks: true,
            fill: false,
            backgroundColor: '#0f6234',
            borderColor: '#0f6234',
            yAxisID: 'y-axis-1',
            borderWidth: 1,
            display: false
          },
          {
            label: `${this.chartTitles()[1]} (%)`,
            lineTension: 0,
            data: this.fanspeedAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#71751d',
            backgroundColor: '#71751d',
            yAxisID: 'y-axis-2',
            borderDash: [2, 2],
            borderWidth: 1,
            display: false
          },
          {
            label: `${this.chartTitles()[2]} (°C)`,
            lineTension: 0,
            data: this.temperatureAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#194698',
            backgroundColor: '#194698',
            yAxisID: 'y-axis-2',
            borderDash: [2, 2],
            borderWidth: 1,
            display: false
          }]
        }
      }
    },
    options () {
      const options = {
        legend: {
          display: false
        },
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: 'M O N i T O R'
        },
        tooltips: {
          mode: 'nearest',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'rgba(255,255,255, 0.1)'
              },
              drawticks: false,
              display: false,
              scaleLabel: {
                display: true,
                labelString: 'T i m e (mins)'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255,255,255, 0.1)'
              },
              drawticks: true,
              display: true,
              position: 'left',
              id: 'y-axis-1',
              scaleLabel: {
                display: false,
                labelString: `H a s h r a t e (${this.unit}/s) `
              },
              ticks: {
                min: this.hashrateMin,
                max: this.hashrateMax,
                stepSize: this.calculateStepSize
              }
            },
            {
              gridLines: {
                color: 'rgba(255,255,255, 0.0)'
              },
              drawticks: true,
              display: true,
              position: 'right',
              id: 'y-axis-2',
              scaleLabel: {
                display: false,
                labelString: 'T e m p (°C) / F a n s (%)'
              },
              ticks: {
                min: 0,
                max: 100,
                stepSize: 10
              }
            }
          ]
        }
      }
      return options
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    scaledHashrate (amount, multi) {
      const scaled = amount / Math.pow(1000, multi)
      return scaled
    },
    fetchMulti (hashrateUnit) {
      return this.hashrateScales.findIndex(scale => scale === hashrateUnit)
    },
    chartTitles () {
      if (!this.isMobile()) {
        if (this.product_mode !== 'pro') {
          return ['  ' + this.$gettext('REPORTED HASHRATE'), '  ' + this.$gettext('FANSPEED AVERAGE'), '  ' + this.$gettext('TEMPERATURE AVERAGE')]
        } else {
          return ['  HR REPORTED', '  FANS', '  TEMP', '  HR POOL']
        }
      } else {
        if (this.product_mode !== 'pro') {
          return ['HR', 'FANS', 'TEMP']
        } else {
          return ['HR', 'FANS', 'TEMP', 'HR POOL']
        }
      }
    }
  }
}
</script>
