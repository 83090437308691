<template>

  <div class="farmCard col-lg-2dot4 col-md-3 col-sm-6 fI-slow">

    <div style="width: 100%; height: 255px;">

      <transition-group name="component-fade">

        <div key="data01" v-if="show_01" style="width:100%; float:right!important; position: absolute!important; padding-right: 20px;">

          <div class="container_wrapper_overview evenRow statsBox trans">

            <div class="container_header_detail_overview" style="color: rgb(185 185 185); background-color: #ffffff00!important; font-weight: normal;">
              <span class="swapSlides trans" style="float:left; padding: 2px 0 0 5px;" @click="swapSlides()"><i class="fas fa-caret-square-left fas-small fas-left"></i></span>
              <i class="fas fa-chart-line fas-right" style="padding-left:0px;"></i> <translate>HASHRATE</translate>
              <span class="swapSlides trans" style="float:right; padding: 2px 5px 0 0;" @click="swapSlides()"><i class="fas fa-caret-square-right fas-small fas-right"></i></span>
            </div>

            <div class="container_body_overview" style="background-color: rgb(60 60 60 / 19%);">
              <div style="padding-top: 25px;">
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR LIVE</translate></div>
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important; border-right:none;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR POOL</translate></div>
                </div>
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>0,00 TH/s</b></div>
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>0,00 TH/s</b></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoBodyOverviewPro green" style="font-size: 2.6em!important; border-top: 1px solid #616161; padding-top: 13px; margin-top: 17px;">0,00<small>%</small></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoHeadOverviewPro" style="border: none;font-size: 0.8em!important; margin-top: -10px;"><b>[ DIFFERENCE ]</b></div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div key="data02" v-if="show_02" style="width:100%; float:right!important; position: absolute!important; padding-right: 20px;">

          <div class="container_wrapper_overview evenRow statsBox trans">

            <div class="container_header_detail_overview" style="color: rgb(185 185 185); background-color: #ffffff00!important; font-weight: normal;">
              <span class="swapSlides trans" style="float:left; padding: 2px 0 0 5px;" @click="swapSlides()"><i class="fas fa-caret-square-left fas-small fas-left"></i></span>
              <i class="fab fa-bitcoin fas-right" style="padding-left:0px;"></i> <translate>EARNINGS</translate>
              <span class="swapSlides trans" style="float:right; padding: 2px 5px 0 0;" @click="swapSlides()"><i class="fas fa-caret-square-right fas-small fas-right"></i></span>
            </div>

            <div class="container_body_overview" style="background-color: rgb(60 60 60 / 19%);">
              <div style="padding-top: 25px;">
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-book fas-small fas-left"></i><translate>PENDING</translate></div>
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important; border-right:none;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-clock fas-small fas-left"></i><translate>24H</translate></div>
                </div>
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b><small>₿</small> 0,00</b></div>
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b><small>₿</small> 0,00</b></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoBodyOverviewPro" style="font-size: 2.6em!important; border-top: 1px solid #616161; padding-top: 13px; margin-top: 17px; color: rgb(255, 179, 87);"><small>₿</small>0,00</div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoHeadOverviewPro" style="border: none; font-size: 0.8em!important; margin-top: -10px;"><b>[ TOTAL PAYOUT ]</b></div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div key="data03" v-if="show_03" style="width:100%; float:right!important; position: absolute!important; padding-right: 20px;">

          <div class="container_wrapper_overview evenRow statsBox trans">

            <div class="container_header_detail_overview" style="color: rgb(185 185 185); background-color: #ffffff00!important; font-weight: normal;">
              <span class="swapSlides trans" style="float:left; padding: 2px 0 0 5px;" @click="swapSlides()"><i class="fas fa-caret-square-left fas-small fas-left"></i></span>
              <i class="fas fa-bolt fas-right" style="padding-left:0px;"></i> <translate>POWER DRAW</translate>
              <span class="swapSlides trans" style="float:right; padding: 2px 5px 0 0;" @click="swapSlides()"><i class="fas fa-caret-square-right fas-small fas-right"></i></span>
            </div>

            <div class="container_body_overview" style="background-color: rgb(60 60 60 / 19%);">
              <div style="padding-top: 25px;">
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-book fas-small fas-left"></i><translate>CURRENT</translate></div>
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important; border-right:none;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-clock fas-small fas-left"></i><translate>24H</translate></div>
                </div>
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>0<small>W</small></b></div>
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>0<small>W</small></b></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoBodyOverviewPro" style="font-size: 2.6em!important; border-top: 1px solid #616161; padding-top: 13px; margin-top: 17px; color: #d3e262;">0,00<small>W</small></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoHeadOverviewPro" style="border: none; font-size: 0.8em!important; margin-top: -10px;"><b>[ TOTAL CONSUMPTION ]</b></div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div key="data04" v-if="show_04" style="width:100%; float:right!important; position: absolute!important; padding-right: 20px;">

          <div class="container_wrapper_overview evenRow statsBox trans">

            <div class="container_header_detail_overview" style="color: rgb(185 185 185); background-color: #ffffff00!important; font-weight: normal;">
              <span class="swapSlides trans" style="float:left; padding: 2px 0 0 5px;" @click="swapSlides()"><i class="fas fa-caret-square-left fas-small fas-left"></i></span>
              <i class="fas fa-dollar-sign fas-right" style="padding-left:0px;"></i> <translate>EXPANSES</translate>
              <span class="swapSlides trans" style="float:right; padding: 2px 5px 0 0;" @click="swapSlides()"><i class="fas fa-caret-square-right fas-small fas-right"></i></span>
            </div>

            <div class="container_body_overview" style="background-color: rgb(60 60 60 / 19%);">
              <div style="padding-top: 25px;">
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-clock fas-small fas-left"></i><translate>24H</translate></div>
                  <div class="col-6 cInfoHeadOverviewPro trans" style="font-size: 0.8em!important;color: rgb(130 130 130)!important; border-right:none;" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-clock fas-small fas-left"></i><translate>30D</translate></div>
                </div>
                <div class="row row-detail" style="width:90%; margin:auto;">
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>$0,00</b></div>
                  <div class="col-6 cInfoBodyOverviewPro" style="font-size: 1em!important;"><b>$0,00</b></div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoBodyOverviewPro" style="font-size: 2.6em!important; border-top: 1px solid #616161; padding-top: 13px; margin-top: 17px; color: #95c6c9;"><small>$</small>0,00</div>
                </div>
                <div class="row row-detail">
                  <div class="col-12 cInfoHeadOverviewPro" style="border: none; font-size: 0.8em!important; margin-top: -10px;"><b>[ TOTAL EXPANSES ]</b></div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </transition-group>

    </div>

  </div>

</template>

<script>
export default {
  name: 'StatsCard',
  props: ['latestFarmStat'],
  data () {
    return {
      show_01: true,
      show_02: false,
      show_03: false,
      show_04: false,
      intervalId: null,
      timerRunning: false
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    fadeData () {
      if (this.timerRunning === false) {
        this.intervalId = setInterval(() => {
          this.timerRunning = true
          if (this.show_01 === true) {
            this.show_01 = false
            this.show_02 = true
            this.show_03 = false
            this.show_04 = false
          } else if (this.show_02 === true) {
            this.show_01 = false
            this.show_02 = false
            this.show_03 = true
            this.show_04 = false
          } else if (this.show_03 === true) {
            this.show_01 = false
            this.show_02 = false
            this.show_03 = false
            this.show_04 = true
          } else if (this.show_04 === true) {
            this.show_01 = true
            this.show_02 = false
            this.show_03 = false
            this.show_04 = false
          }
        }, 8000)
      }
    },
    swapSlides () {
      clearInterval(this.intervalId)
      this.timerRunning = false
      if (this.show_01 === true) {
        this.show_01 = false
        this.show_02 = true
        this.show_03 = false
        this.show_04 = false
      } else if (this.show_02 === true) {
        this.show_01 = false
        this.show_02 = false
        this.show_03 = true
        this.show_04 = false
      } else if (this.show_03 === true) {
        this.show_01 = false
        this.show_02 = false
        this.show_03 = false
        this.show_04 = true
      } else if (this.show_04 === true) {
        this.show_01 = true
        this.show_02 = false
        this.show_03 = false
        this.show_04 = false
      }
      this.fadeData()
    }
  },
  computed: {
  },
  mounted () {
    this.fadeData()
  }
}
</script>
