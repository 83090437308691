<template>

  <div class="farmCard col-lg-2dot4 col-md-3 col-sm-6">

    <div class="container_wrapper_overview evenRow fI">

      <div style="display:none;">{{ isProvisioned }}</div>

      <div class="container_header_detail_overview">
        <span class="farmCardTitleWrap">
          <!--img v-if='renderStatComps && isProvisioned' src="@/assets/img_ext/coins/BTC.png" width="17" style="margin-top: -5px; margin-bottom: -3px;" :title="$gettext('...LONG!')"-->
          <i v-if='renderStatComps && isProvisioned' class="wobble fas fas-xsBtn fas-left fa-search-plus" style="font-size: 1em!important; margin-right: -4px; color: #bdbdbd; cursor: pointer;"></i>
          <i v-else class="wobble fas fas-xsBtn fas-left fa-question-circle" style="font-size: 1.1em!important; margin-right: -4px; color: #bdbdbd; cursor: pointer;" @click="$refs.noDataForFarmModal.openModal();" :title="$gettext('No data for device-set') + ': '+ deviceset_id +' ' + $gettext('Click for more information.')"></i>
          <div style="padding-left:4px; display:inline;">
            <a v-if='renderStatComps && isProvisioned' :href="metrics + '/d/dae5e0ac-32e5-45bb-a738-54b19b7627e9/device-set-detail?var-deviceset_id=' + farm.deviceset_id" target="_blank">
              <span style="padding-top:3px; font-size: 1em; color: #bdbdbd; cursor: pointer;" :title="$gettext('Show detailed metrics & more...')">{{ name }}</span>
            </a>
            <span v-else style="padding-top:3px; font-size: 1em; color: #bdbdbd; cursor: pointer;" @click="$refs.noDataForFarmModal.openModal();" :title="'Device-Set ID: '+ deviceset_id">{{ name }}</span>
            <i class="fas fa-cogs fas-small fas-left color10 inlineEditIcon" style="padding-left:8px; float: left; margin-top:3px; cursor: pointer;" :title="$gettext('Edit settings.')" @click="$refs.FarmSettings.$refs.editFarm.openModal();"></i>
            <a @click="$refs.DeviceImporter.$refs.addDevicesModal.openModal();" style="padding-right:8px; float: right;" :title="$gettext('Add devices.')"><i class="fas fa-plus-circle fas-small grow green" style="font-size: 0.8em;"></i></a>
            <a @click="$refs.deleteFarmModal.openModal();" style="padding-right:6px; float: right; margin-left: -18px;" :title="$gettext('Delete device-set including all associated data.')"><i class="fas fa-minus-circle fas-small grow deepred" style="font-size: 0.8em;"></i></a>
          </div>
        </span>
      </div>

      <!-- STATS -->

      <div class="container_body_overview fI" style="display:block;">

        <div class="canvas-wrapper-overview">
          <Chart v-if='isProvisioned && isOnline' class="fI-slow" style="ChartOverview" :farmId='id' :stats='stats' :chartColors='chart.chartColors' :unit='hashrateUnit'/>
          <div v-else-if='isProvisioned && !isOnline' class="loaderState text-secondary fI-slow" role="status">
            <div class="loader loaderOffline" style="background-color: rgb(217 34 44 / 80%);"></div>
            <div class="small pulsate loaderTxt farmOffline">[ <translate>OFFLINE</translate> ]</div>
          </div>
          <div v-else-if='renderStatComps && !isProvisioned && !isOnline' class="loaderState text-secondary fI-slow" role="status">
            <div class="loader loaderWaiting" style="background-color: rgb(21 164 85 / 70%);"></div>
            <div class="small pulsate loaderTxt waitingForMiners">[ <translate>WAITING FOR MINERS</translate> ]</div>
          </div>
          <div v-else class="loaderState text-secondary fI-slow" role="status">
            <div class="loader"></div>
            <div class="small pulsate loaderTxt">[ <translate>INITIALIZING</translate> ]</div>
          </div>
        </div>

        <div class="dataFadeWrapper" style="">

          <div class="dataFade" style="height:48px!important;">

            <Stats v-if='renderStatComps && isProvisioned && isOnline' :latestFarmStat='latestFarmStat' :product_mode='product_mode' style="width:100%!important;"/>
            <div v-else class="text-secondary fI-slow" role="status">
              <div v-if='product_mode == "bos"'>
                <div class="row row-detail">
                  <div class="col-4 cInfoHeadOverview"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR</translate></div>
                  <div class="col-4 cInfoHeadOverview"><i class="fas fa-fan fas-small fas-left"></i><translate>FANS</translate></div>
                  <div class="col-4 cInfoHeadOverview" style="border-right:none!important;"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TEMP</translate></div>
                </div>
                <div class="row row-detail" v-if='renderStatComps && !isProvisioned && !isOnline'>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                </div>
                <div class="row row-detail" v-else>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard"></div></div>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard"></div></div>
                  <div class="col-4 cInfoBodyOverview"><div class="loaderFarmCard"></div></div>
                </div>
              </div>
              <div v-else>
                <div class="row row-detail">
                  <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('The total hashrate of all miners combined as reported by the devices.')"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>HR RT</translate></div>
                  <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Number of total asics and online asics.')"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TEMP</translate></div>
                  <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Average temperature of all miners combined.')"><i class="fas fa-fan fas-small fas-left"></i><translate>FANS</translate></div>
                  <div class="col-3 cInfoHeadOverviewPro trans" :title="$gettext('Average fan speed of all miners combined.')" style="border-right:none!important;"><i class="fas fa-server fas-small fas-left"></i><translate>ASICS</translate></div>
                </div>
                <div class="row row-detail" v-if='renderStatComps && !isProvisioned && !isOnline'>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard" style="color: rgb(21 164 85 / 70%)"></div></div>
                </div>
                <div class="row row-detail" v-else>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard"></div></div>
                  <div class="col-3 cInfoBodyOverviewPro"><div class="loaderFarmCard"></div></div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <!-- COMPONENT : FARM SETTiNGS -->

    <FarmSettings v-if="farm" v-bind:farm="farm" v-bind:worker_mode="farm.worker_mode" ref="FarmSettings" />

    <!-- COMPONENT : ADD DEVICES TO DEVICE-SET -->

    <DeviceImporter ref="DeviceImporter" v-if="product_mode === 'pro' && farm" v-bind:farm="farm"/>

    <DeviceImporterCloud ref="DeviceImporter" v-if="product_mode === 'cloud' && farm" v-bind:farm="farm"/>

    <!-- MODAL : DELETE DEVICE-SET -->

    <Modal ref="deleteFarmModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-times fas-left"></i> <translate>DELETE</translate> <small class="color01">_ <translate>DEVICE-SET</translate></small></h5>
      </template>
      <template v-slot:body>
        <div><i class="grow fas fa-times fas-small fas-left deepred" style="font-size: 4em; color: #d9222c;"></i></div>
        <div class="pulsate deepred" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px; color: #d9222c;"> <translate>DELETE DEVICE-SET</translate> <span class="yellow"><b>{{ farm.name }}</b></span> ?</div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button type="button" class='btn btn-primary' style="margin-top:-1px; margin-right: 5px;" @click="$refs.deleteFarmModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CANCEL</translate></button>
          <button type="button" class='btn btn-danger' @click="deleteFarm();$refs.deleteFarmModal.closeModal();"><i class="fas fas-xsBtn fa-check fas-left"></i><translate>YES</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : COPY FARM_ID -->

    <Modal ref="copyDeviceSetIdModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-fingerprint fas-left"></i> <translate>YOUR DEVICE-SET ID</translate></h5>
      </template>
      <template v-slot:body>
        <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
          <div class="pulsate color06" style="letter-spacing: 1px; font-size: 1em; margin-top: 0px; font-weight: bold;"> {{ farm.deviceset_id }}</div>
          <div class="pulsate" style="margin-top:12px;">
            <div v-translate>
              The <b>Device-Set ID</b> has been copied to the clipboard!
            </div>
            <div v-translate>
              Use it during <b>Braiins OS+</b> installation.
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-success' @click="$refs.copyDeviceSetIdModal.closeModal();"><i class="fas fas-xsBtn fa-check fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : NO DATA RECEIVED -->

    <Modal ref="noDataForFarmModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-database fas-left"></i> <translate>NOTHING TO SHOW</translate> <small class="color01">_ <translate>YET</translate></small></h5>
      </template>
      <template v-slot:body>
        <div class="canvas-wrapper grey" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
          <span v-translate>First you have to <b style="color:white;">ADD</b> devices to this device-set. Once done, it should take no more than a couple of minutes for them to show up.</span>
          <!--div class='field-with-right-button'>
            <FormulateInput
                disabled='disabled'
                :label="$gettext('DEVICE-SET ID')"
                :tooltip="$gettext('This is the unique identifier of the device-set and can not be changed.')"
                type='text'
                v-model='farm.deviceset_id'
                :placeholder="$gettext('Waiting for server... Please wait!')"
            />
            <button style="float: right;height: 30px;width: 30px;margin-left: 5px;margin-right: 0px;" class='btn btn-primary' @click.prevent="copyDeviceSetId()" :title="$gettext('Copy the Device-Set ID to clipboard.')"><i class="fas fas-xsBtn fa-copy"></i></button>
          </div-->
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-secondary' @click="$refs.noDataForFarmModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CLOSE</translate></button>
          <!--button class='btn btn-warning' @click="$refs.noDataForFarmModal.closeModal();copyDeviceSetId();$refs.copyDeviceSetIdModal.openModal();"><i class="fas fas-xsBtn fa-copy fas-left"></i><translate>COPY DEVICE-SET ID</translate></button-->
          <button class='btn btn-success' @click="$refs.noDataForFarmModal.closeModal();$refs.DeviceImporter.$refs.addDevicesModal.openModal();"><i class="fas fas-xsBtn fa-plus fas-left"></i><translate>ADD DEVICES</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
import Farm from '@/models/farm'
import FarmStat from '@/models/farm-stat'
import Chart from './FarmCard/Chart'
import Stats from './FarmCard/Stats'
import { FARM_STATS__READ, FARM__DEACTIVATE, FARM__DELETE } from '@/store/actions.type'
import Modal from '@/components/widgets/Modal.vue'
import FarmSettings from '@/components/widgets/FarmSettings.vue'
import DeviceImporter from '@/components/widgets/DeviceImporter.vue'
import DeviceImporterCloud from '@/components/widgets/DeviceImporterCloud.vue'
import { mapGetters } from 'vuex'
import { PRODUCT_MODE, METRICS_PROTO, METRICS_FQDN } from '@/common/config'

export default {
  name: 'farm',
  components: { Chart, Stats, Modal, FarmSettings, DeviceImporter, DeviceImporterCloud },
  props: ['id', 'name', 'owners', 'deviceset_id', 'is_active', 'miners', 'config'],
  data () {
    return {
      product_mode: PRODUCT_MODE,
      metrics: METRICS_PROTO + '://' + METRICS_FQDN,
      timeNow: Math.floor(Date.now() / 1000),
      intervalId: null,
      renderStatComps: false,
      chart: {
        chartColors: {
          red: 'rgb(255, 99, 132)',
          orange: 'rgb(255, 159, 64)',
          yellow: 'rgb(255, 205, 86)',
          green: 'rgb(75, 192, 192)',
          blue: 'rgb(54, 162, 235)',
          purple: 'rgb(153, 102, 255)',
          grey: 'rgb(201, 203, 207)'
        }
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    }
  },
  mounted () {
    this.$store.dispatch(FARM_STATS__READ, { farmId: this.id, count: 9 }).then((result) => {
      if (this.latestFarmStat) {
        this.renderStatComps = true
      }
    })
  },
  computed: {
    ...mapGetters(['currentUser']),
    farm () { return Farm.find(this.id) },
    stats () {
      return FarmStat.lastInRangeForFarm(this.id, 9)
    },
    hashrateUnit () {
      return this.latestFarmStat.hashrate_unit
    },
    latestFarmStat () {
      return FarmStat.lastForFarm(this.id)
    },
    isOnline () {
      return this.latestFarmStat && this.latestFarmStat.miner_count_online > 0 && (this.latestFarmStat.created > (this.timeNow - 900))
    },
    isProvisioned () {
      if (this.latestFarmStat && this.latestFarmStat.created) {
        return true
      } else {
        this.renderStats()
        return false
      }
    }
  },
  methods: {
    renderStats () {
      this.renderStatComps = true
    },
    deleteFarm () {
      this.$store.dispatch(FARM__DELETE, this.id)
      this.$parent.removeFarm(this.farm.deviceset_id)
    },
    deactivateFarm () {
      this.$store.dispatch(FARM__DEACTIVATE, this.id)
      this.$parent.removeFarm(this.farm.deviceset_id)
    },
    async copyDeviceSetId () {
      await navigator.clipboard.writeText(this.farm.deviceset_id)
    }
  }
}
</script>
